import React from 'react';
import { t } from 'i18next';
import routes from '../../../../routing/routes';
import { reverse } from 'named-urls';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import { TableBody } from '../../../../components';
import { ApiPairingService } from '../../../../services/api/pairing.service';

class TablePatientInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: this.props.settings.phoneSocket ? this.props.settings.phoneSocket : {},
        };
    }

    render() {
        return (
            <Table className="table-patient-info">
                <TableBody>
                    {this.props.showName && this.props.data.fullName && (
                        <tr>
                            <td width="150">{t('tools.task:patient:name')}</td>
                            <td><strong>{this.formatValue('fullName')}</strong></td>
                        </tr>
                    )}
                    {['birthdate', 'email', 'phone', 'mobile', 'info'].map(field => {
                        return (
                            <tr key={field}>
                                <td>{t('tools.task:patient:' + field)}</td>

                                {['phone', 'mobile'].indexOf(field) > -1 && (this.state.phone.connected && this.state.phone.selectedDevice) ? (
                                    <td>
                                        <Button color="link" className="p-0 ml-0" onClick={() => this.handleCallClick(this.formatValue(field))}>
                                            {this.formatValue(field)}
                                        </Button>
                                    </td>
                                ) : (
                                    <td dangerouslySetInnerHTML={{ __html: this.formatValue(field) }}></td>
                                )}
                            </tr>
                        );
                    })}

                    <tr>
                        <td colSpan={2}>
                            <Button color="link" className="px-0 mx-0" onClick={this.onEditClick}>Patiënten gegevens aanpassen</Button>
                        </td>
                    </tr>
                </TableBody>
            </Table>
        );
    }

    onEditClick = () => {
        if (this.props.onClick) this.props.onClick();
        this.props.history.push(reverse(routes.patients.edit, { id: this.props.data.id }))
    }

    formatValue = (field) => {
        let value = this.props.data[field] ? this.props.data[field] : null;

        if (!value) return '-';

        if (field === 'birthdate') {
            return moment(value).format('DD/MM/YYYY');
        }

        return value;
    }

    handleCallClick = (number) => ApiPairingService.call({
        number: number,
        phone: this.state.phone,
        group: this.props.group.id
    });
}

export default TablePatientInfo;
