import React from 'react';
import { t } from 'i18next';
import Select from 'react-select';
import { UtilDate } from '../../../utils/date.util';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Col } from 'reactstrap';

class DatetimeField extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        let date = props.data[props.field.name] ? UtilDate.create(props.data[props.field.name]) : false;

        this.state = {
            dtOpen: false,
            field: props.field.name,
            date: date ? UtilDate.format(date, 'DD/MM/YYYY') : null,
            hour: date ? UtilDate.format(date, 'HH') : '00',
            minute: date ? UtilDate.format(date, 'mm') : '00'
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.bindListeners();
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    onChange = (date, hour, minute) => {
        const fullDate = UtilDate.create(this.assembleDate(date, hour, minute), 'DD/MM/YYYY HH:mm');

        this.props.onChangeValue(this.props.field.name, UtilDate.format(fullDate, 'YYYY-MM-DD HH:mm:00'));
    }

    handleDateChange = (name, date) => {
        this.updateState({
            date: moment(date).format('DD/MM/YYYY')
        });

        const { hour, minute } = this.state;
        this.onChange(moment(date).format('DD/MM/YYYY'), hour, minute);
    };

    handleChange = (moment) => {
        const { hour, minute } = this.state;

        if (typeof moment === 'string') {
            this.updateState({ date: moment, dtOpen: false });
            return;
        }

        this.updateState({
            date: moment.format('DD/MM/YYYY'),
            dtOpen: false
        });

        this.onChange(moment.format('DD/MM/YYYY'), hour, minute);
    }

    handleHourChange = (selected) => {
        const { value } = selected;
        const { date, minute } = this.state;

        this.updateState({
            hour: value
        });

        this.onChange(date, value, minute);
    }

    handleMinuteChange = (selected) => {
        const { value } = selected;
        const { date, hour } = this.state;

        this.updateState({
            minute: value
        });

        this.onChange(date, hour, value);
    }

    assembleDate = (date, hour, minute) => {
        return date + ' ' + hour + ':' + minute;
    }

    render() {
        const { name, readonly } = this.props.field;
        let date = this.getDateFromProps();

        return (
            <div className="row form-row form-inline form-date-time-picker">
                <Col className="date" xs={12} md={this.props.small ? 5 : 3}>
                    <Datetime
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        open={this.state.dtOpen}
                        onChange={this.handleChange}
                        input={true}
                        value={date}
                        name="date"
                        id={name}
                        inputProps={{
                            name: name,
                            id: name,
                            disabled: readonly,
                            onClick: this.onClick
                        }}
                    />
                </Col>

                <Col className="time" xs={6} md={this.props.small ? 3 : 2}>
                    {this.renderHourSelect()}
                </Col>
                <Col className="time" xs={6} md={this.props.small ? 3 : 2}>
                    {this.renderMinutesSelect()}
                </Col>
            </div>
        );
    }

    bindListeners = () => {
        document.addEventListener('click', (e) => {
            let paths = e.path || (e.composedPath && e.composedPath());
            if (!paths || !paths.length) {
                return;
            }

            let outsideRdt = true;
            paths.forEach((path) => {
                if (path === undefined) return;
                if (path && path.classList && path.classList.contains('rdt') && (e.target.name === undefined || e.target.name === this.props.field.name)) {
                    outsideRdt = false;
                }
            });

            if (outsideRdt) {
                this.updateState({ dtOpen: false });
            }
        });
    }

    onClick = (e) => {
        this.updateState({
            dtOpen: !this.state.dtOpen
        });
    }

    renderHourSelect = () => {
        let { readonly } = this.props.field;

        let date = this.getDateFromProps();
        let hour = date ? date.format('HH') : false;

        if (this.props.field.options.onlyDateDisabled) {
            readonly = false;
        }
        let selectedHour = null;
        const hours = this.renderTimeOptions(24);
        hours.forEach((option) => {
            if (String(hour) === option.value) {
                selectedHour = option;
            }
        });

        return (
            <Select
                className="form-select"
                name="hour"
                simpleValue
                value={selectedHour}
                options={hours}
                isDisabled={readonly}
                noOptionsMessage={this.noOptionsMessage}
                placeholder={t('component.form:hour')}
                onChange={this.handleHourChange}
            />
        );
    }

    renderMinutesSelect = () => {
        let { readonly } = this.props.field;

        let date = this.getDateFromProps();
        let minute = date ? date.format('mm') : false;
        if (this.props.field.options.onlyDateDisabled) {
            readonly = false;
        }

        let selected = null;
        const options = this.renderTimeOptions(60);
        options.forEach((option) => {
            if (String(minute) === option.value) {
                selected = option;
            }
        });

        return (
            <Select
                className="form-select"
                name="minutes"
                simpleValue
                value={selected}
                options={options}
                isDisabled={readonly}
                noOptionsMessage={this.noOptionsMessage}
                placeholder={t('component.form:minutes')}
                onChange={this.handleMinuteChange}
            />
        );

    }
    renderTimeOptions = (max) => {
        let options = [];
        let pad = '00';

        for (let i = 0; i < max; i++) {
            let val = '' + i;
            val = pad.substring(0, pad.length - val.length) + i;
            options.push({ value: val, label: val });
        }

        return options;
    }

    noOptionsMessage = (input) => {
        const { inputValue } = input;

        return t('component.form:noOptions', { value: inputValue });
    }

    changeEndDate = (date) => this.updateState({ date: date.format('DD/MM/YYYY'), hour: date.format('HH'), minute: date.format('mm') });

    updateState = (state) => {
        if (!this._isMounted) return;
        this.setState(state);
    }

    getDateFromProps = () => {
        let { data, field } = this.props;
        return data[field.name] ? UtilDate.create(data[field.name]) : false;
    }
};

export default DatetimeField;