import React from 'react';
import { t } from 'i18next';
import Select from 'react-select';

class FilterSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectOptionsMulti: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        const { id, onChange, defaultValue, options, label, placeholder } = this.props;

        if (this.props.multiple) {
            return (
                <label htmlFor={id} className="mr-2 w-50">
                    <Select
                        id={id}
                        simpleValue
                        isMulti
                        placeholder={placeholder}
                        onChange={this.handleChangeSelectMulti}
                        options={Object.entries(options).map(([id, title]) => { return { value: id.trim(), label: title } })}
                        isSearchable={true}
                    />
                </label>
            );
        }

        return (
            <label htmlFor={id} className="mr-2">
                {label}
                <select id={id} onChange={onChange} className="form-control form-control-sm ml-2" defaultValue={defaultValue ? defaultValue : null}>
                    <option value="">{t('tools.task:filter-select-all-option')}</option>
                    {Object.keys(options).map(key => {
                        let { label, disabled } = options[key];
                        return <option key={key} value={key} disabled={disabled}>{label}</option>
                    })}
                </select>
            </label>
        );
    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        let selectedOptions = [];
        selectedOptionMulti.forEach((option, i) => {
            selectedOptions.push(option.value);
        });

        this.setState({ selectOptionsMulti: selectedOptions });

        this.props.onChange();
    }
}

export default FilterSelect;