import React from 'react';
import { t } from 'i18next';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import routes from '../../../routing/routes';
import { reverse } from 'named-urls';
import { UtilClient } from '../../../utils';

class SidebarGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ddOpen: false
        };
    }

    changeGroup = (item) => {
        this.props.actions.groupUpdate(item);
        this.props.actions.setPatient(null);

        let client = this.props.client;
        if (!client || !client.hasAgenda) {
            client = item.clients.filter(gClient => UtilClient.hasAccessToAgenda(client, gClient.id))[0];
        }

        if (client) {
            this.props.actions.clientSet('active', client);
        }

        //reload
        let location = this.props.location.pathname;

        if (location.startsWith(reverse(routes.agenda.client))) {
            if (client && !client.hasAgenda) {
                this.props.history.push(reverse(routes.agenda.group));
            } else if (client) {
                this.props.history.push(reverse(routes.agenda.client, { client: client.id }));
            } else if (this.props.client) {
                this.props.history.push(reverse(routes.agenda.client, { client: this.props.client.id }));
            } else {
                this.props.history.push(reverse(routes.agenda.client));
            }
        } else {
            this.props.history.push(this.props.location.pathname);
        }
    }

    toggle = () => this.setState({ ddOpen: !this.state.ddOpen })

    render() {
        if (!this.props.client.groups) return null;

        if (this.props.client.groups.length === 1) {
            return (
                <ul className="sidebar-nav">
                    <li className="nav-heading">
                        <span>{this.props.group.name}</span>
                    </li>
                </ul>
            )
        } else {
            let btnColor = this.props.group ? 'brand' : 'danger';
            let wideMenu = this.props.client.groups.filter(group => group.name.length > 20).length;
            
            return (
                <ButtonDropdown color={btnColor} isOpen={this.state.ddOpen} toggle={this.toggle} className="btn-block">
                    <DropdownToggle color={btnColor} size="sm" caret>
                        {this.props.group ? this.props.group.name : t('common:choose.group')}
                    </DropdownToggle>
                    <DropdownMenu className={wideMenu ? 'dropdown-menu-wide' : ''}>
                        {this.props.client.groups.map(item => (
                            <DropdownItem key={item.id} className={wideMenu ? 'dropdown-item-ws-normal' : ''} onClick={() => this.changeGroup(item)} active={!this.props.group || item.id !== this.props.group.id ? false : true}>
                                {item.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(null, mapDispatchToProps)(SidebarGroup);