import { API } from '../api';

const getPatient = (id, extraData) => API.get('client/patient/patient/get-patient', { id: id, extraData: extraData }).then(response => API.isSuccess(response) ? response.data.patient : null);
const getByGroup = (groupId) => API.get('client/patient/patient/get-by-group', { id: groupId }).then(response => API.isSuccess(response) ? response.data.patients : []);
const getByCallerId = (groupId, callerId) => API.get('client/patient/patient/get-by-caller-id', { group: groupId, callerId: callerId }).then(response => API.isSuccess(response) ? response.data.callerResults : []);

const getUnconfirmedPatients = (groupId) =>
    API.get('client/patient/patient/get-unconfirmed-patients', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.patients;
            }
            throw response.data;
        });

const confirmDecline = (id, confirm, message) =>
    API.post('client/patient/patient/confirm', { data: { id: id, confirm: confirm, message: message } })
        .then(response => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw response.data;
        });

const search = (data) => API.get('client/patient/patient/search', data).then(response => API.isSuccess(response) ? response.data.patients : []);
const searchDt = (data) => API.get('client/patient/patient/search-dt', data).then(response => response.data ? response.data : []);
const searchOverview = (data) => API.get('client/patient/patient/search-overview', data).then(response => API.isSuccess(response) ? response.data.patients : []);

const resetStatus = (data) => API.post('client/patient/patient/reset-status', { data: data }).then(response => API.isSuccess(response) ? response.data.patient : []);

const quickUpdate = (data) => API.post('client/patient/patient/quick-update', { data: data }).then(response => API.isSuccess(response) ? response.data.patient : null);

const getFilters = (groupId) => API.get('client/patient/patient/get-filters', { groupId: groupId }).then(response => API.isSuccess(response) ? response.data.filters : []);
const saveFilter = (id, data) => API.post('client/patient/patient/save-filter', { id: id, data: data }).then(response => API.isSuccess(response) ? response.data.filter : false);

export const ApiPatientService = {
    getPatient,
    getByGroup,
    getByCallerId,
    getUnconfirmedPatients,
    confirmDecline,
    search,
    searchDt,
    searchOverview,
    resetStatus,
    getFilters,
    saveFilter,
    quickUpdate
};