import { t } from 'i18next';
import { UtilNotification } from '../../utils';
import { API } from '../api';

const call = (data) => {
    try {
        if (!data.phone.connected) {
            throw new Error('phone not connected');
        }

        if (!data.phone.selectedDevice) {
            throw new Error('device not selected');
        }

        API.post('client/pairing/egeo/call', {
            data: {
                group: data.group,
                device: data.phone.selectedDevice,
                number: data.number
            }
        })
            .then(response => {
                if (API.isSuccess(response)) {
                    console.log('calling...');
                    return true;
                }
                throw new Error(response.data[0])
            })
            .catch(() => UtilNotification.toastError(t('common:errorMessage')));
    } catch (e) {
        UtilNotification.toastError(e.message);
    }
};

export const ApiPairingService = {
    call
};