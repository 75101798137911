import React from 'react';
import { t } from 'i18next';
import { Alert, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import moment from 'moment';
import { BadgeTag, BadgeTooltip } from '../badge';

class AlertCaller extends React.Component {
    constructor(props) {
        super(props);

        this._dismissed = false;
    }

    componentDidUpdate() {
        if (!this._dismissed) return;

        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
        this._dismissed = false;
    }

    render() {
        if (this.props.selectedPatient) {
            return this.renderSelected();
        } else if (this.props.caller && this.props.caller.id) {
            return this.renderList(this.props.caller);
        }

        return null;
    }

    renderList = (caller) => (
        <Alert color="default" className="bg-default" id="patient-alert-book-appointment">
            <strong>{t('patients.patient:caller:incoming')}</strong>: {caller.id}

            <Button color="danger" size="sm" className="float-right" data-dismiss="alert" aria-label="Close" onClick={this.onListCancelClick}>
                <span className="fa fa-times" aria-hidden="true"></span>
            </Button>

            <p>{t('patients.patient:caller:possible-patients')}</p>
            <hr className="mt-2" />

            <div className="alert-content">
                {caller.results.length <= 0 && <p>{t('patients.patient:caller:no-results')}</p>}
                {caller.results.length > 0 && caller.results.map(patient => {
                    return this.renderPatientBadge(patient);
                })}
            </div>
        </Alert>
    )

    renderPatientBadge = (patient) => (
        <Button key={patient.id} color="default" className="m-1 text-left" onClick={() => this.onPatientClick(patient)}>
            {patient.fullName} - {moment(patient.birthdate).format('DD/MM/YYYY')}
            {patient.status_counters.map(status => (

                <BadgeTooltip key={patient.id + '-' + status.id} id={patient.id + '-' + status.id} className="ml-1 py-1"
                    color={status.bgColor} tooltip={status.status}
                    style={{ backgroundColor: status.bgColor, color: status.textColor }}
                >
                    {status.code}: {status.count}
                </BadgeTooltip>
            ))}
        </Button>
    );

    onPatientClick = (data) => {
        this.props.actions.setPatient({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            nickname: data.nickname,
            birthdate: data.birthdate,
            email: data.email,
            phone: data.phone,
            mobile: data.mobile,
            gender: data.gender,
            nationalInsuranceNumber: data.nationalInsuranceNumber,
            tags: data.tags,
            smartfield1: data.smartfield1,
            smartfield2: data.smartfield2,
            smartfield3: data.smartfield3,
            smartfield4: data.smartfield4,
            smartfield5: data.smartfield5,
            street: data.street,
            number: data.number,
            box: data.box,
            postalCode: data.postalCode,
            city: data.city,
            country: data.country,
            nationality: data.nationality,
            info: data.info
        });

        if (this.props.onSet) {
            this.props.onSet()
        }
    }

    renderSelected = () => {
        let { selectedPatient, caller } = this.props;

        return (
            <Alert color="default" id="patient-alert-book-appointment">
                {caller && caller.id && <><strong>{t('patients.patient:caller:incoming')}</strong>: {this.props.caller.id}</>}
                {(!caller || !caller.id) && <strong>{t('agenda.appointment:patient-alert:title')}</strong>}

                <div className="float-right">
                    {caller && caller.results && caller.results.length > 0 && (
                        <Button color="primary" size="sm" className="ml-2" data-dismiss="alert" aria-label="Close" onClick={this.onSelectedPatientCancelClick}>
                            <span aria-hidden="true">{t('patients.patient:caller:selected:other')}</span>
                        </Button>
                    )}
                    <Button color="danger" size="sm" className="ml-2" data-dismiss="alert" aria-label="Close" onClick={this.onListCancelClick}>
                        <span className="fa fa-times" aria-hidden="true"></span>
                    </Button>
                </div>
                <hr className="mt-4" />
                <strong>{t('patients.patient:caller:selected:title')}</strong> - {t('agenda.appointment:patient-alert:text')}

                <div className="alert-content d-flex">
                    <div>{t('patients.patient:firstName')}:<br /> <strong>{selectedPatient.firstName}</strong></div>
                    <div>{t('patients.patient:lastName')}:<br /> <strong>{selectedPatient.lastName}</strong></div>
                    <div>{t('patients.patient:birthdate')}:<br /> <strong>{moment(selectedPatient.birthdate).format('DD/MM/YYYY')}</strong></div>
                    <div>{t('patients.patient:phone')}:<br /> <strong>{selectedPatient.telephone ? selectedPatient.telephone : '-'}</strong></div>
                    <div>{t('patients.patient:mobile')}:<br /> <strong>{selectedPatient.mobile ? selectedPatient.mobile : '-'}</strong></div>
                    <div>{t('patients.patient:email')}:<br /> <strong>{selectedPatient.email ? selectedPatient.email : '-'}</strong></div>
                </div>

                {selectedPatient.tags && (
                    <div className="alert-content d-flex">
                        <div>
                            {selectedPatient.tags.map(tag => <BadgeTag tag={tag} key={tag.id} />)}
                        </div>
                    </div>
                )}
            </Alert>
        );
    }

    onListCancelClick = () => {
        this.props.actions.changeSetting('caller', null);
        this.props.actions.setPatient(null);
        this.clear();
        this._dismissed = true;
    }

    onSelectedPatientCancelClick = () => {
        this.props.actions.setPatient(null);
        this.clear();
    }

    clear = () => {
        if (this.props.onClear) {
            this.props.onClear()
        }
    }
}

const mapStateToProps = state => ({ selectedPatient: state.patient ? state.patient : null, caller: state.settings && state.settings.caller ? state.settings.caller : null })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(AlertCaller);