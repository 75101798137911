import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Table, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Badge, Col, Row } from 'reactstrap';
import Spinner from '../reactstrap/Spinner';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { UtilDate, UtilColor, UtilUrl, UtilNotification } from '../../utils';
import { ApiAppointmentService } from '../../services/api/appointment.service';
import { Trans } from 'react-i18next';
import { ModalAppointmentLog, ModalPayment, ModalPatientInfo, ModalRepeatedAppointments } from '.';
import moment from 'moment';
import { BadgeCategory, BadgeIcon, BadgeStatus, BadgeSubcategory, BadgeTag } from '../badge';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { ButtonActionCustom, ButtonIcon, ButtonLabel } from '../buttons';
import { ApiPairingService } from '../../services/api/pairing.service';
import { AlertWarning } from '../alert';

class ModalAppointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            statusDropdown: false,
            appointment: null,
            phone: this.props.stateSettings.phoneSocket ? this.props.stateSettings.phoneSocket : {},
            modal: true,
            nestedModal: false,
            nestedModalRepeated: false,
            nestedPaymentModal: false
        };
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
    }

    componentDidMount() {
        this.props.onModalRef(this);

        this.loadData();
    }

    componentDidUpdate() {
        if (!this.state.appointment) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.props.onModalRef(null);
    }

    renderModalLoad = () => {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <div className="modal-header">
                    <div className="modal-title text-bold">{t('agenda.myagenda:appointmentModal:title')}</div>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <Spinner visible={true} />
                </ModalBody>
            </Modal>
        );
    }

    render() {
        const { appointment, loading } = this.state;

        if (loading) {
            return this.renderModalLoad();
        }

        let { payment, appointmentData, reminderEmail, reminderMobile, start, end, appointmentStatus, categoryId, category, subcategoryId, subcategory, repeatSequence, videoConsult, bookedBy, bookedByType, bookedOn, updatedBy, updatedOn, canceledBy, canceled, canceledReason, videoConsultUrl, patient, warningMessage, reservedMinutesBefore, reservedMinutesAfter, differentDuration } = appointment;

        bookedOn = UtilDate.format(bookedOn, 'ddd DD/MM/YYYY HH:mm');
        updatedOn = UtilDate.format(updatedOn, 'ddd DD/MM/YYYY HH:mm');
        canceled = canceled ? UtilDate.format(canceled, 'ddd DD/MM/YYYY HH:mm') : null;

        let appointmentStart = moment(start);
        let appointmentEnd = moment(end);

        let reservedStart = moment(start);
        let reservedEnd = moment(end);
        if (!!reservedMinutesBefore) {
            reservedStart = moment(start).subtract(reservedMinutesBefore, 'minutes');
        }
        if (!!reservedMinutesAfter) {
            reservedEnd = moment(end).add(reservedMinutesAfter, 'minutes');
        }

        let showBackButton = false;
        const { appointmentsForOverview, event } = this.props;
        if (appointmentsForOverview && appointmentsForOverview.length > 1) {
            showBackButton = true;
        }

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    {showBackButton &&
                        <Col lg={1} md={6}>
                            <Button color="default" id="buttonBack" onClick={() => this.props.onBackClick(event, appointmentsForOverview)}>
                                <i className="fa fa-arrow-left"></i>
                            </Button>
                            <UncontrolledTooltip target="buttonBack">
                                {t('common:back.to.overview')}
                            </UncontrolledTooltip>
                        </Col>
                    }
                    <Col lg={2} md={12}>
                        <div className="modal-title text-bold">{t('agenda.myagenda:appointmentModal:title')}</div>
                        {videoConsult && <BadgeIcon icon="video" id={'video-' + appointment.id} color="primary" className="mr-2" tooltip={t('common:videoConsult')} />}
                        {repeatSequence && (
                            <>
                                <Badge style={{ cursor: 'pointer' }} onClick={() => this.onRepeatSequenceClick()} color="default" className="px-2 py-1 mr-2">[{repeatSequence}]</Badge>
                                {this.renderRepeatedModal()}
                            </>
                        )}
                    </Col>
                    <Col lg={6} md={12}>
                        <BadgeCategory className="mr-2" id={categoryId} title={category} />
                        {subcategory && <BadgeSubcategory className="mr-2" id={subcategoryId} title={subcategory} />}
                        {appointmentStatus && <BadgeStatus className="mr-2" id={appointmentStatus} />}

                        {payment.applicable && (
                            <ButtonIcon icon="euro-sign" className={'float-right mr-1 text-' + t('settings.payment:status:color:' + payment.status)} id={'payment-' + appointment.id} tooltip="Online betaling" onClick={this.onAppointmentPaymentModalClick} />
                        )}
                        {patient.hasMobile && patient.id && (
                            <ButtonIcon icon="mobile-alt" className="float-right mr-1" id={'sms' + patient.id} tooltip={t('patients.patient:send-sms')} onClick={() => this.onPatientSmsClick(patient.id)} />
                        )}

                        {patient.id && (
                            <>
                                <ButtonIcon icon="user" className="float-right mr-1" id={patient.id} tooltip={t('patients.patient:info')} onClick={() => this.onPatientDetailClick(patient.id)} />
                                {this.renderPatientInfoModal()}
                            </>
                        )}
                    </Col>
                    <Col lg={showBackButton ? 3 : 4} md={12}>
                        {t('agenda.appointment:date')}: <strong>{appointmentStart.format('ddd DD/MM/YYYY')}</strong><br />
                        {t('agenda.appointment:from')}: <strong>{appointmentStart.format('HH:mm')} - {appointmentEnd.format('HH:mm')}</strong>

                        {differentDuration && (
                            <>
                                <span className='fa fa-exclamation-triangle ml-1' id="appointment-time-warning"></span>
                                <UncontrolledTooltip placement="top" target="appointment-time-warning">
                                    {differentDuration}
                                </UncontrolledTooltip>
                            </>
                        )}

                        {(!!reservedMinutesBefore || !!reservedMinutesAfter) &&
                            <div id="total-reserved-time">
                                <span className='fa fa-ban mr-1'></span>
                                {reservedStart.format('HH:mm')} - {reservedEnd.format('HH:mm')}
                                <span className='fa fa-info-circle ml-1' id="appointment-time-info"></span>
                                <UncontrolledTooltip target="appointment-time-info">
                                    <strong>{t('agenda.appointment:reserved:tooltip.appointment')}:</strong><br />
                                    {appointmentStart.format('HH:mm')} - {appointmentEnd.format('HH:mm')}
                                    <br /><br />
                                    <strong>{t('agenda.appointment:reserved:tooltip.title')}:</strong><br />
                                    {!!reservedMinutesBefore && <><span>{t('agenda.appointment:reserved:tooltip.before')}: {reservedMinutesBefore}'</span><br /></>}
                                    {!!reservedMinutesAfter && <><span>{t('agenda.appointment:reserved:tooltip.after')}: {reservedMinutesAfter}'</span><br /></>}
                                    <br />
                                    <strong>{t('agenda.appointment:reserved:tooltip.total')}:</strong><br />
                                    {reservedStart.format('HH:mm')} - {reservedEnd.format('HH:mm')}
                                </UncontrolledTooltip>
                            </div>
                        }
                    </Col>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>

                <ModalBody>
                    {warningMessage && <AlertWarning message={warningMessage} />}

                    <Table className="table table-borderless table-responsive">
                        <tbody>
                            {bookedByType === 5 && (
                                <tr>
                                    <td colSpan={2}>
                                        Deze afspraak is geboekt op naam van <strong>{patient.name}</strong>.<br />
                                        Verdere gegevens zijn niet beschikbaar vanwege de automatische import.
                                    </td>
                                </tr>
                            )}

                            {appointmentData.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="modal-appointment-data-col" width="200"><strong>{data.label}</strong></td>
                                        {['phone', 'mobile'].indexOf(data.type) > -1 && (this.state.phone.connected && this.state.phone.selectedDevice) ? (
                                            <td>
                                                <Button color="link" className="p-0" onClick={() => this.handleCallClick(data.value)}>
                                                    {data.value}
                                                </Button>
                                            </td>
                                        ) : (
                                            <td dangerouslySetInnerHTML={{ __html: data.value }}></td>
                                        )}
                                    </tr>
                                )
                            })}

                            {videoConsult && !appointment.canceled && (
                                <tr>
                                    <td className="modal-appointment-data-col"><strong>{t('common:videoConsult')}</strong></td>
                                    <td>
                                        <ButtonLabel color="primary" icon="video" className="btn-video" onClick={() => window.open(videoConsultUrl, '_blank')}>
                                            {t('common:videoConsult')}
                                        </ButtonLabel>
                                    </td>
                                </tr>
                            )}

                            {(reminderEmail || reminderMobile) && (
                                <>
                                    <tr><td colSpan={2}>&nbsp;</td></tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="modal-title"><strong>{t('agenda.appointment:reminder')}</strong></div>
                                        </td>
                                    </tr>

                                    {reminderEmail && <tr>
                                        <td className="modal-appointment-data-col"><strong>{t('patients.patient:email')}</strong></td>
                                        <td>
                                            {reminderEmail}
                                            {!appointment.canceled && (
                                                <ButtonActionCustom id={'resendConfirmation' + appointment.id}
                                                    color="default" size="xs" icon="paper-plane" className="ml-2"
                                                    tooltip={t('agenda.appointment:confirmation.resend.mail')}
                                                    onClick={() =>
                                                        ApiAppointmentService.sendNotification({ id: appointment.id, type: 'mail-appointment-confirmation' })
                                                            .then(() => UtilNotification.toastSuccess(t('profile.mailbox:send.successMessage')))
                                                            .catch(() => UtilNotification.toastError(t('common:error.general')))
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>}
                                    {reminderMobile && <tr>
                                        <td className="modal-appointment-data-col"><strong>{t('patients.patient:mobile')}</strong></td>
                                        <td>{reminderMobile}</td>
                                    </tr>}
                                </>
                            )}

                            {patient.tags.length > 0 && (
                                <>
                                    <tr><td colSpan={2}>&nbsp;</td></tr>
                                    <tr>
                                        <td colSpan={2} className="modal-appointment-data-col">
                                            <strong>{t('patients.patient:tags')}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            {patient.tags.map(tag => <BadgeTag tag={tag} key={tag.id} />)}
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter className="text-left">
                    <div>
                        <Badge onClick={this.onStatusLogClick} className="px-2 mr-2 float-right statuslogInfoBtn" pill style={{ backgroundColor: '#f05050' }}>{t('modal.appointmentstatuslog:modal.log.history')}</Badge>
                        {this.renderBingliBadge()}
                        <div className="modal-title"><strong>{t('modal.appointmentstatuslog:modal.info.events')}</strong></div>
                    </div>
                    <Trans i18nKey="agenda.appointment:bookedBy" bookedBy={bookedBy} bookedOn={{ bookedOn }}>
                        Geboekt door <strong>{{ bookedBy }}</strong> op <strong>{{ bookedOn }}</strong>
                    </Trans><br />
                    {bookedOn !== updatedOn && !canceled && (
                        <Trans i18nKey="agenda.appointment:updatedBy" updatedBy={updatedBy} updatedOn={{ updatedOn }}>
                            Laatste update: <strong>{{ updatedOn }}</strong> door <strong>{{ updatedBy }}</strong>
                        </Trans>)}<br />

                    {canceled && (
                        <Trans i18nKey="agenda.appointment:canceledBy" canceledBy={canceledBy} canceledReason={canceledReason} canceled={{ canceled }}>
                            Geannuleerd door <strong>{{ canceledBy }}</strong> op <strong>{{ canceled }}</strong> met reden <strong>{{ canceledReason }}</strong>
                        </Trans>
                    )}
                </ModalFooter >

                {!appointment.canceled && (
                    <ModalFooter className="text-center">
                        {this.renderStatusDropdown(appointmentStatus)}

                        <div className="modal-buttons">
                            <Button color="default" className="btn-labeled btn-print" onClick={this.onPrintClick}>
                                <span className="btn-label"><i className={'fa fa-print'} aria-hidden></i></span>
                                <div className="btn-text">{t('common:Print')}</div>
                            </Button>

                            {!!appointment.clientId && (
                                <>
                                    <Button color="default" className="btn-labeled btn-move" onClick={() => this.handleAppointmentAction('move', appointment)}>
                                        <span className="btn-label"><i className="fas fa-arrows-alt" aria-hidden></i></span>
                                        <div className="btn-text">{t('agenda.appointment:move')}</div>
                                    </Button>
                                    <Button color="default" className="btn-labeled btn-move" onClick={() => this.handleAppointmentAction('copy', appointment)}>
                                        <span className="btn-label"><i className="fa fa-copy" aria-hidden></i></span>
                                        <div className="btn-text">{t('agenda.appointment:copy')}</div>
                                    </Button>
                                </>
                            )}
                            <Button color="default" className="btn-labeled btn-edit" onClick={this.onEditClick}>
                                <span className="btn-label"><i className="fa fa-pencil-alt" aria-hidden></i></span>
                                <div className="btn-text">{t('common:Edit')}</div>
                            </Button>
                        </div>

                        <div className="modal-buttons">
                            {this.props.showCancelButton && (
                                <Button color="danger" className="btn-labeled btn-delete" onClick={this.onCancelClick}>
                                    <span className="btn-label"><i className="fa fa-trash" aria-hidden></i></span>
                                    <div className="btn-text">{t('common:Cancel-with-reason')}</div>
                                </Button>
                            )}

                            <Button color="danger" className="btn-labeled btn-delete" onClick={this.onRemoveClick}>
                                <span className="btn-label"><i className="fa fa-trash" aria-hidden></i></span>
                                <div className="btn-text">{t('common:Remove')}</div>
                            </Button>
                        </div>
                    </ModalFooter>
                )}
            </Modal >
        );
    }

    renderBingliBadge = () => {
        if (!this.state.appointment.hasBinlgiSurvey) return null;

        return <Badge onClick={this.onBingliSurveyClick} className="px-2 mr-2 float-right statuslogInfoBtn" pill color={this.state.appointment.bingliState === 'finished' ? 'success' : 'danger'}>
            {t('agenda.appointment:bingli.modal.title')}
        </Badge>
    }


    loadData = () => ApiAppointmentService.getById(this.props.appointment.id).then(result => this.setState({ loading: false, appointment: result }));

    renderStatusDropdown = (selectedStatusId) => {
        const { statuses } = this.props.schemaData;

        let title = 'Verander status';
        let bgColor = null;
        if (selectedStatusId && selectedStatusId in statuses) {
            title = statuses[selectedStatusId].title;
            bgColor = statuses[selectedStatusId].backgroundColor;
        }

        return (
            <Dropdown className="d-inline-block mr-md-2" isOpen={this.state.statusDropdown} toggle={this.onStatusDropdownToggle}>
                <DropdownToggle caret color="primary" style={UtilColor.generateStyleAttributeColors(bgColor)}>
                    {title}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.onStatusChange(null)}>
                        <Badge color="light" pill>{t('common:None')}</Badge>
                    </DropdownItem>
                    {Object.values(statuses).filter(status => status.groupId === this.props.group.id).map(status =>
                        <DropdownItem key={status.id} onClick={() => this.onStatusChange(status.id)}>
                            <Badge pill style={UtilColor.generateStyleAttributeColors(status.backgroundColor)}>{status.title}</Badge>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    }

    onStatusDropdownToggle = () => this.setState({ statusDropdown: !this.state.statusDropdown });


    onStatusChange = (id) => ApiAppointmentService.quickUpdate(this.state.appointment.id, { update: 'status', newValue: id })
        .then(() => {
            this.onToggle();
            UtilNotification.toastSuccess(t('agenda.appointment:edit.successfull'));
            this.props.load();
        })
        .catch(error => UtilNotification.toastError(error));

    onPatientSmsClick = (patientId) => {
        let { url, params } = this.props.match;
        const { appointment } = this.state;

        let client = appointment.clientId;
        let date = moment(appointment.start).format('YYYY-MM-DD-HH-mm');

        let link = reverse(routes.agenda.client, { client: client, date: date });
        if (url.includes('/subgroup')) {
            link = reverse(routes.agenda.subgroup, { subgroup: params.subgroup, date: date });
        } else if (url.includes('/agenda/group')) {
            link = reverse(routes.agenda.group, { date: date });
        }

        this.onToggle();
        this.props.history.push(reverse(routes.patients.sms, { id: patientId }) + '?redirect=' + encodeURIComponent(link))
    }

    onEditClick = () => {
        const { appointment } = this.state;

        this.onToggle();

        if (appointment.isHomeVisit) {
            this.props.history.push(reverse(routes.homevisits.edit, { id: appointment.id, client: appointment.clientId }));
        } else if (appointment.type === 4 && !appointment.clientId) {
            this.props.history.push(reverse(routes.appointments.open.edit, { id: appointment.id }));
        } else {
            this.props.history.push(reverse(routes.appointments.edit, { id: appointment.id, client: appointment.clientId }));
        }
    }

    onPrintClick = () => window.open(reverse(routes.appointments.print, { id: this.state.appointment.id }));

    onCancelClick = () => {
        let { start } = this.state.appointment;

        let params = {};

        if (this.props.parent) {
            let redirectParams = this.props.parent.state.return.params;
            redirectParams.date = moment(start).format('YYYY-MM-DD-HH-mm');

            params.redirectUrl = reverse(this.props.parent.state.return.route, redirectParams);
        }

        this.props.modalAppointmentCancel.fire({ id: this.state.appointment.id }, params);
        this.onToggle();
    }

    onRemoveClick = () => {
        let { id, repeatId, start } = this.state.appointment;

        let params = {};

        if (this.props.parent) {
            let redirectParams = this.props.parent.state.return.params;
            redirectParams.date = moment(start).format('YYYY-MM-DD-HH-mm');

            params.redirectUrl = reverse(this.props.parent.state.return.route, redirectParams);
        }

        this.props.removeModal.fire({ id: id, repeatId: repeatId, dateFrom: start }, params);
        this.onToggle();
    }

    onRemoveRepeatedClick = (type = null) => {
        let { repeatId, start } = this.state.appointment;

        let params = { id: repeatId };
        if (type === 'upcoming') {
            params.dateFrom = start;
        }
        this.props.removeRepeatedModal.fire(params);
        this.onToggle();
    }

    handleAppointmentAction = (action, appointment) => {
        let { actions, history, groupOverview, subgroup } = this.props;

        actions.changeSetting('modus', action);
        actions.changeSetting('modusParams', { appointment: appointment.id, hasFollowUps: appointment.type === 3 });

        let params = { date: moment(appointment.start).format('YYYY-MM-DD-HH-mm') };
        let route = routes.agenda.group;

        if (subgroup) {
            route = routes.agenda.subgroup;
            params.subgroup = subgroup.id;
        } else if (!groupOverview) {
            params.client = appointment.clientId;
            route = routes.agenda.client;
        }
        history.push(UtilUrl.generate(route, params));
        this.onToggle();
    }

    onToggle = () => this.setState({ modal: !this.state.modal });
    onStatusLogClick = () => ReactDOM.render(<ModalAppointmentLog appointmentId={this.state.appointment.id} schemaData={this.props.schemaData} />, document.getElementById('modal-section'));
    onBingliSurveyClick = () => window.open(this.state.appointment.bingliSummaryLink, '_blank');

    // Patient info modal

    renderPatientInfoModal = () => <ModalPatientInfo isOpen={this.state.nestedModal} toggle={this.toggleNested} toggleAll={this.toggleAll} patientId={this.state.appointment.patientId} {...this.props} />
    onPatientDetailClick = () => this.setState({ nestedModal: true });
    toggleNested = () => this.setState({ nestedModal: !this.state.nestedModal });
    toggleAll = () => this.setState({ nestedModal: !this.state.nestedModal }, () => this.onToggle());

    // Repeated appointments modal
    renderRepeatedModal = () => <ModalRepeatedAppointments id={this.state.appointment.repeatId} isOpen={this.state.nestedModalRepeated} toggle={this.toggleNestedRepeated} toggleAll={this.toggleAllRepeated} {...this.props} />

    onRepeatSequenceClick = () => this.setState({ nestedModalRepeated: true });
    toggleNestedRepeated = () => this.setState({ nestedModalRepeated: !this.state.nestedModalRepeated });
    toggleAllRepeated = () => {
        this.toggleNestedRepeated();
        this.onToggle();
    }

    // Payment modal
    onAppointmentPaymentModalClick = () => this.setState({ nestedPaymentModal: true }, () => ReactDOM.render(<ModalPayment type="appointment" id={this.state.appointment.id} payment={this.state.appointment.payment} onToggle={this.toggleNestedPayment} parent={this} />, document.getElementById('modal-section')));
    toggleNestedPayment = (reload) => reload ? this.reload() : null;
    reload = () => this.setState({ loading: true }, () => this.loadData());

    handleCallClick = (number) => ApiPairingService.call({
        number: number,
        phone: this.state.phone,
        group: this.props.group.id
    });
}

export default ModalAppointment;