import React from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { t } from 'i18next';
import { UtilEvent } from '../../../utils/event.util';

class ChoiceField extends React.Component {
    constructor(props) {
        super(props);

        this._hasChoiceListener = (props.field.name === 'gender' || props.field.name === 'country' || (props.field.options.patientData && (props.field.options.patientData === 'gender' || props.field.options.patientData === 'country')));

        this.state = {
            field: props.field,
            options: [],
            selectedOption: null,
            selectedOptionMulti: null,
            checkedOptionMulti: []
        };
    }

    componentWillReceiveProps(newProps) {
        this.assembleOptions(newProps);
    }

    handleChangeSelect = (selectedOption) => {
        if (selectedOption === null) {
            selectedOption = { value: null, data: null }
        }

        //this.setState({ selectedOption: selectedOption });
        this.props.onChangeValue(this.props.field.name, selectedOption.value);

        let el = document.querySelector('input[name="' + this.props.field.name + '"]');
        if (el) {
            UtilEvent.dispatch(el, 'change', { value: selectedOption.value, data: selectedOption.data ? selectedOption.data : null });
        }
    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        this.setState({ selectedOptionMulti });
        let selectedOptions = [];
        selectedOptionMulti.forEach((option, i) => {
            selectedOptions.push(option.value);
        });

        this.props.onChangeValue(this.props.field.name, selectedOptions);
    }

    handleChangeCheckboxMulti = (clickedCheckbox) => {
        let input = clickedCheckbox.target;
        let checkedOptionMulti = this.state.checkedOptionMulti;
        let index = checkedOptionMulti.indexOf(input.value);

        if (input.checked) {
            if (index === -1) {
                checkedOptionMulti.push(input.value.toString());
            }
        } else {
            if (index > -1) {
                checkedOptionMulti.splice(index, 1);
            }
        }

        this.setState({ checkedOptionMulti });
        this.props.onChangeValue(this.props.field.name, checkedOptionMulti);
    }

    handleChangeRadio = (clickedRadioBox) => {
        let input = clickedRadioBox.target;
        let field = this.state.field;
        field.data = input.value;

        this.setState({ field });
        this.props.onChangeValue(this.props.field.name, input.value);
    }

    assembleOptions = (props) => {
        let options = [];
        let optionGroups = [];

        let choiceGroups = props.field.options.choiceGroups ? props.field.options.choiceGroups : {};

        Object.entries(props.field.choices).forEach(([key, choice]) => {
            key = key.replace(/___/g, '');

            let optionData = { value: '' + key, label: choice };
            if (props.field.options.choices && props.field.options.choices['___' + key]) {
                optionData.isDisabled = props.field.options.choices['___' + key].disabled;
            }

            if (props.field.options.data && props.field.options.data[key]) {
                optionData.data = props.field.options.data[key];
            }

            if (key in choiceGroups) {
                if (!(choiceGroups[key] in optionGroups)) {
                    optionGroups[choiceGroups[key]] = {
                        label: choiceGroups[key],
                        options: []
                    };
                }
                optionGroups[choiceGroups[key]]['options'].push(optionData);
            } else {
                options.push(optionData);
            }
        });
        options = options.concat(Object.values(optionGroups));
        
        let fieldData = props.data[props.field.name];

        let selectedOptionMulti = [];
        let checkedOptionMulti = [];

        fieldData && Object.entries(fieldData).forEach(([index, key]) => {
            selectedOptionMulti = this.assembleSelectedOptionMulti(key, options, selectedOptionMulti);

            checkedOptionMulti.push('' + key);
        });

        let selectedOption = this.getSelectedOption(options, fieldData);

        this.setState({
            field: props.field,
            options: options,
            selectedOption: selectedOption,
            selectedOptionMulti: selectedOptionMulti,
            checkedOptionMulti: Object.values(checkedOptionMulti)
        }, () => this.initListener());
    }

    assembleSelectedOptionMulti = (key, options, selectedOptionMulti) => {
        for (const i in options) {
            if (String(key) === options[i].value) {
                selectedOptionMulti.push(options[i]);
            }

            if (options[i].options) {
                this.assembleSelectedOptionMulti(key, options[i].options, selectedOptionMulti);
            }
        }
        return selectedOptionMulti;
    }

    getSelectedOption = (options, fieldData) => {
        let selected = null;

        for (let i in options) {
            if (String(fieldData) === options[i].value) {
                selected = options[i];
            }

            if (!selected && options[i].options) {
                selected = this.getSelectedOption(options[i].options, fieldData);
            }
        }

        return selected;
    }

    initListener = () => {
        if (!this._hasChoiceListener) return;
        document.addEventListener('choiceChanged', this.handleChoiceChanged);
    }

    componentDidMount = () => {
        this.assembleOptions(this.props);
        this.bindValueChangedListener();
    }

    componentWillUnmount() {
        if (!this._hasChoiceListener) return;
        document.removeEventListener('choiceChanged', this.handleChoiceChanged);
    }

    render() {
        const { multiple, expanded } = this.state.field;

        if (expanded === false) {
            if (multiple === true) {
                return this.renderMultiSelect();
            } else {
                return this.renderSingleSelect();
            }
        } else {
            if (multiple === true) {
                return this.renderCheckboxes();
            } else {
                return this.renderRadioButtons();
            }
        }
    }

    renderSingleSelect = () => {
        const { name, readonly, placeholder } = this.state.field;
        let { options, selectedOption } = this.state;

        return (
            <Select name={name} id={name}
                className="form-select"
                simpleValue
                value={selectedOption}
                onChange={this.handleChangeSelect}
                options={options}
                isDisabled={readonly}
                isClearable
                noOptionsMessage={this.noOptionsMessage}
                placeholder={placeholder}
                filterOption={this.filterOption}
                isSearchable={this.state.field.options.allowSearch ? this.state.field.options.allowSearch : false}
            />
        );
    }

    renderMultiSelect = () => {
        return (
            <Select
                className="form-select"
                name={this.state.field.name}
                simpleValue
                isMulti
                value={this.state.selectedOptionMulti}
                onChange={this.handleChangeSelectMulti}
                options={this.state.options}
                isDisabled={this.state.field.readonly}
                noOptionsMessage={this.noOptionsMessage}
                placeholder={this.state.field.placeholder}
                filterOption={this.filterOption}
                isSearchable={this.state.field.options.allowSearch ? this.state.field.options.allowSearch : false}
            />
        );
    }

    renderCheckboxes = () => {
        const { name, readonly } = this.state.field;
        let { options, checkedOptionMulti } = this.state;

        if (options.length <= 0) {
            if (this.state.field.options.key && this.state.field.options.key === 'mailing_list_filters') {
                return <p className='text-muted'>
                    {t('communication.mailing:list:filters.noResult')}
                </p>
            }
        }

        return (
            options.map((option, i) => {
                let checked = false;
                for (let j = 0; j < checkedOptionMulti.length; j++) {
                    if (option.value.toString() === checkedOptionMulti[j].toString()) {
                        checked = true;
                    }
                }

                let style = {};
                if (name === 'daysOfWeek' || name === 'repeatRuleValue_days-of-week') {
                    style = { width: '10%', display: 'table-cell' };
                }

                return (<div key={i} className="checkbox c-checkbox" style={style}>
                    <label>
                        <Input type="checkbox" value={option.value ? option.value.toString() : '1'} onChange={this.handleChangeCheckboxMulti} name={name} defaultChecked={checked ? true : false} disabled={readonly} />
                        <span className="fa fa-check"></span> {option.label}
                    </label>
                </div>)
            })
        );
    }

    renderRadioButtons = () => {
        const { name, data, readonly } = this.state.field;
        let { options } = this.state;

        return (
            options.map((option, i) => {
                let checked = option.value === data ? true : false;

                return (<div key={i} className="c-radio">
                    <label>
                        <Input type="radio" value={option.value ? option.value : 1} onChange={this.handleChangeRadio} name={name} defaultChecked={checked ? true : false} disabled={readonly} />
                        <span className="fa fa-circle"></span> {option.label}
                    </label>
                </div>)
            })
        );
    }

    noOptionsMessage = (input) => {
        const { inputValue } = input;

        return t('component.form:noOptions', { value: inputValue });
    }

    filterOption = (option, value, data) => {
        let label = String(option.label).toLowerCase();
        value = value.toLowerCase();

        return label.indexOf(value) > -1;
    }

    handleChoiceChanged = (e) => {
        if (this.state.selectedOption) return;

        Object.entries(this.state.options).forEach(([i, item]) => {
            if (e.detail.value === item.value) {
                this.setState({ selectedOption: item });

                if (e.detail.triggerChange) {
                    this.props.onChangeValue(this.props.field.name, item.value);
                }
            }
        });
    }

    bindValueChangedListener = () => {
        let { changeValue } = this.props.field.options;

        if (!changeValue) return;

        document.addEventListener('valueChanged', (e) => {
            let { changedField, newValue } = e.detail;
            if (!(changedField in changeValue)) return;

            let newFieldValue = null;
            if (changeValue[changedField]['type'] === 'value') {
                newFieldValue = changeValue[changedField]['values'][newValue];
            }

            if (!newFieldValue) return; // No new value, nothing to update

            let newSelectedOption = null;
            this.state.options.forEach((option) => {
                if (option.value === newFieldValue.toString()) {
                    newSelectedOption = option;
                }
            });

            this.setState({
                selectedOption: newSelectedOption
            });

            this.handleChangeSelect(newSelectedOption);
        });
    }
}

export default ChoiceField;