import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import Datatable from '../../components/Datatable';
import TaskActions from '../../components/datatable/TaskActions';
import ModalRemove from '../../components/modals/ModalRemove';
import FilterSelect from '../../components/datatable/filters/FilterSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { ApiClientService } from '../../services/api/client.service';
import { ApiTaskService } from '../../services/api/task.service';
import { DropdownActions } from '../dropdown';
import ReactTooltip from 'react-tooltip';
import { BadgeSubcategory, ModalPayment, ModalTask, ModalTaskLog } from '..';
import moment from 'moment';
import FilterDate from './filters/FilterDate';
import { UtilNotification, UtilUrl } from '../../utils';

class DataTableTask extends React.Component {
    constructor(props) {
        super(props);

        this._dataTable = null;

        let me = this;

        this.state = {
            groups: [],
            defaultFilters: this.initDefaultFilters(),
            dataTableOptions: {
                columns: [
                    { data: 'status', className: 'text-center', orderable: false },
                    { data: 'priority', className: 'text-center', orderable: true },
                    { data: 'title' },
                    {
                        render: function (data, type, full, meta) {
                            let el = document.createElement('div');
                            el.id = 'task-assigned-to-' + full.id;
                            el.textContent = full.assigned && full.assigned.length > 0 ? full.assigned.join(', ') : '-';
                            return el.outerHTML;
                        },
                        className: 'table-assigned-to',
                        orderable: false
                    },
                    { data: 'patient', className: 'text-center', orderable: false },
                    {
                        data: 'dateCreated', className: 'text-center', orderable: true, render: (data, type, full, meta) => {
                            return moment(full.dateCreated).format('ddd DD/MM/YYYY HH:mm')
                        }
                    },
                    {
                        data: 'dateExpired', className: 'text-center', orderable: true, render: (data, type, full, meta) => {
                            return full.dateExpired ? moment(full.dateExpired).format('ddd DD/MM/YYYY HH:mm') : '-';
                        }
                    },
                    {
                        render: function (data, type, full, meta) {
                            let el = document.createElement('div');
                            el.id = 'task-actions-' + full.id;
                            return el.outerHTML;
                        },
                        className: 'table-actions',
                        orderable: false
                    }
                ],
                order: [[1, 'asc'], [5, 'desc'], [6, 'desc']],
                serverSide: true,
                processing: true,
                ajax: {
                    url: process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/client/task/task/search-dt',
                    type: 'GET',
                    headers: {
                        'Authorization': props.client ? props.client.access_key : null,
                        'X-Group': props.group ? props.group.id : null
                    },
                    //data: this.assembleFilters()
                    data: {
                        id: props.group.id,
                        filterStatus: () => document.getElementById('dataTables_filter_status') ? document.getElementById('dataTables_filter_status').value : this.state.defaultFilters.status,
                        filterPriority: () => document.getElementById('dataTables_filter_priority') ? document.getElementById('dataTables_filter_priority').value : this.state.defaultFilters.priority,
                        filterClient: () => document.getElementById('dataTables_filter_assigned') ? document.getElementById('dataTables_filter_assigned').value : this.state.defaultFilters.client,
                        filterExpired: () => document.getElementById('dataTables_filter_expired') ? document.getElementById('dataTables_filter_expired').value : this.state.defaultFilters.expired
                    }
                },
                createdRow: function (row, data) {
                    row.id = 'record-' + data.id;
                    row.dataset.itemId = data.id;
                    row.dataset.patientId = data.patientId;
                    row.dataset.watching = data.watching;
                },
                drawCallback: function (data) {
                    me.renderUnassignedBadge(data);

                    data.aoData.forEach(row => {
                        data = row._aData;
                        let rowEl = document.getElementById('record-' + data.id);

                        if (rowEl) {
                            ReactDOM.render(<DropdownActions item={data} label={t('tools.task:statuses:text:' + data.status)} color={t('tools.task:statuses:colors:' + data.status)} options={me.initStatuses()} onChange={me.onStatusChange} />, rowEl.querySelector('td:nth-child(1n)'));
                            ReactDOM.render(<DropdownActions item={data} label={t('tools.task:priorities:text:' + data.priority)} color={t('tools.task:priorities:colors:' + data.priority)} options={me.initPiorities()} onChange={me.onPriorityChange} />, rowEl.querySelector('td:nth-child(2n)'));

                            // Title
                            if (data.hasContent) {
                                let infoIndicatorEl = document.createElement('span');
                                infoIndicatorEl.classList.add('icon-info', 'ml-1');

                                let description = data.description;
                                if (description.length > 500) {
                                    description = description.substring(0, 500) + '...';
                                }

                                infoIndicatorEl.setAttribute('data-tip', description);

                                rowEl.querySelector('td:nth-child(3n)').appendChild(infoIndicatorEl);
                            }

                            // Subcategory label
                            if (data.subcategory) {
                                let subcatEl = document.createElement('span');
                                rowEl.querySelector('td:nth-child(3n)').appendChild(subcatEl);

                                ReactDOM.render(<BadgeSubcategory key={data.subcategory.id + '-' + data.id} id={data.subcategory.id} className="ml-2" />, subcatEl);
                            }

                            rowEl.querySelector('td:nth-child(4n)').textContent = data.assigned && data.assigned.length > 0 ? data.assigned.join(', ') : '-';

                            if (data.patientId) {
                                let patientLink = document.createElement('a');
                                patientLink.href = reverse(routes.patients.edit, { id: data.patientId });
                                let patientText = document.createTextNode(data.patient);
                                patientLink.appendChild(patientText);
                                rowEl.querySelector('td:nth-child(5n)').textContent = '';
                                rowEl.querySelector('td:nth-child(5n)').appendChild(patientLink);
                            } else {
                                rowEl.querySelector('td:nth-child(5n)').textContent = data.patient;
                            }

                            ReactDOM.render(<TaskActions id={data.id} data={data} onLogClick={id => me.onStatusLogClick(id)} onPaymentModalClick={me.onPaymentModalClick} onDetailClick={id => me.renderDetailModal(id)} toggleWatcher={id => me.onWatcherToggle(id)} remove={(id) => me.modalRemove.fire({ id: id })} {...props} />, rowEl.lastChild);
                        }
                    });

                    ApiClientService.getNotifications(props.group.id).then(result => props.actions.notificationsLoaded(result));
                    ReactTooltip.rebuild();
                }
            }
        };

        this.removeModalProps = {
            removeUrl: 'client/task/task/remove', // API endpoint URL
            redirectUrl: reverse(routes.communication.tasks.overview),
            successMessage: t('tools.task:remove.successfull'),
            text: t('tools.task:remove.message'),
            onRef: (ref) => this.modalRemove = ref,
            ...props
        }
    }

    componentDidMount() {
        this.loadGroups();

        let filterLengthEl = document.querySelector('.dataTables_length').parentElement;
        if (filterLengthEl) {
            this.renderPrioritiesFilter();
            this.renderStatusFilter();
            this.renderAssignedFilter();
            this.renderExpiredFilter();
            this.renderResetButton();

            filterLengthEl.classList.remove('col-md-6');
            filterLengthEl.classList.add('col-md-9');
        }

        let filterFilterEl = document.querySelector('.dataTables_filter').parentElement;
        if (filterFilterEl) {
            filterFilterEl.classList.remove('col-md-6');
            filterFilterEl.classList.add('col-md-3');
        }

        this._dataTable.on('draw.dt', this.handleResize);
    }

    render() {
        return (
            <React.Fragment>
                <Datatable options={this.state.dataTableOptions} onRef={ref => this._dataTable = ref} {...this.props}>
                    <table className="table table-striped table-bordered w-100">
                        <thead>
                            <tr>
                                <th className="text-center" width="75">{t('tools.task:table:status')}</th>
                                <th className="text-center" width="75">{t('tools.task:table:priority')}</th>
                                <th className="text-nowrap">{t('tools.task:table:title')}</th>
                                <th width="200">{t('tools.task:table:assigned')}</th>
                                <th width="125">{t('tools.task:table:patient')}</th>
                                <th width="125">{t('tools.task:table:created-date')}</th>
                                <th width="125">{t('tools.task:table:due-date')}</th>
                                <th>{t('common:actions')}</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </Datatable>
                <ModalRemove {...this.removeModalProps} />
            </React.Fragment>
        );
    }

    initDefaultFilters = () => {
        let queryStr = UtilUrl.parseQueryString(this.props.history.location.search);

        return {
            status: queryStr.status !== undefined ? queryStr.status : 'open',
            priority: queryStr.priority !== undefined ? queryStr.priority : null,
            client: queryStr.client !== undefined ? queryStr.client : this.props.defaultAssigend,
            expired: queryStr.date !== undefined ? queryStr.date : null
        };
    }

    renderStatusFilter = () => {
        let filterSection = document.querySelector('.dataTables_length');
        if (!filterSection) return;


        let filterStatusEl = document.createElement('label');
        filterStatusEl.classList.add('dataTables_filter_status', 'ml-3');
        filterSection.appendChild(filterStatusEl);

        ReactDOM.render(<FilterSelect id="dataTables_filter_status" options={this.initStatuses()} defaultValue={this.state.defaultFilters.status} onChange={this.filter} label={t('tools.task:filter-status')} />, filterStatusEl);
    }

    renderExpiredFilter = () => {
        let filterSection = document.querySelector('.dataTables_length');
        if (!filterSection) return;

        let filterEl = document.createElement('label');
        filterEl.classList.add('dataTables_filter_expired', 'ml-3');
        filterSection.appendChild(filterEl);

        ReactDOM.render(<FilterDate id="dataTables_filter_expired" onChange={this.filter} defaultValue={this.state.defaultFilters.expired} label={t('tools.task:filter-expired')} />, filterEl);
    }

    renderResetButton = () => {
        let filterSection = document.querySelector('.dataTables_length');
        if (!filterSection) return;

        let resetEl = document.createElement('button');
        resetEl.classList.add('fa', 'fa-sync-alt', 'btn', 'btn-link', 'text-decoration-none');
        resetEl.setAttribute('data-tip', t('tools.task:filters.buttons.reset.tooltip'));
        resetEl.onclick = () => this.props.history.push(reverse(routes.communication.tasks.overview));
        filterSection.appendChild(resetEl);
    }

    renderPrioritiesFilter = () => {
        let filterSection = document.querySelector('.dataTables_length');
        if (!filterSection) return;

        let filterPriorityEl = document.createElement('label');
        filterPriorityEl.classList.add('dataTables_filter_priority', 'ml-3');
        filterSection.appendChild(filterPriorityEl);

        let priorities = {};
        Object.entries(this.initPiorities()).forEach(([key, status]) => {
            priorities[key] = { label: status.label };
        });

        ReactDOM.render(<FilterSelect id="dataTables_filter_priority" defaultValue={this.state.defaultFilters.priority} options={priorities} onChange={this.filter} label={t('tools.task:filter-priority')} />, filterPriorityEl);
    }

    renderAssignedFilter = () => {
        let filterSection = document.querySelector('.dataTables_length');
        if (!filterSection) return;

        let filterPriorityEl = document.createElement('label');
        filterPriorityEl.classList.add('dataTables_filter_assigned', 'ml-3');
        filterSection.appendChild(filterPriorityEl);

        let options = {};
        this.props.group.clients.forEach(client => {
            options[client.id] = { label: client.fullName };
        });
        options['not-assigned'] = { label: 'Niet toegewezen' };
        options['-'] = { label: '-', disabled: true };

        this.state.groups.forEach(group => {
            options['g-' + group.id] = { label: group.title };
        });

        ReactDOM.render(<FilterSelect id="dataTables_filter_assigned" options={options} defaultValue={this.state.defaultFilters.client} onChange={this.filter} label={t('tools.task:filter-assigned')} />, document.querySelector('.dataTables_filter_assigned'));
    }

    onStatusChange = (id, data) => this.quickUpdate(id, { ...data, ...{ update: 'status' } });
    onPriorityChange = (id, data) => this.quickUpdate(id, { ...data, ...{ update: 'priority' } });
    onStatusLogClick = (task) => ReactDOM.render(<ModalTaskLog task={task} />, document.getElementById('modal-section'));
    onWatcherToggle = (id) => this.quickUpdate(id, { update: 'watcher' });

    quickUpdate = (id, data) => ApiTaskService.quickUpdate(id, data)
        .then(result => {
            if (result) {
                UtilNotification.toastSuccess(t('tools.task:edit.successfull'));
                this.filter();
            }
        })
        .catch(error => UtilNotification.toastError(error));

    filter = () => {
        if (!this._dataTable) return;
        this._dataTable.ajax.reload();
    }

    initStatuses = () => ({
        open: { label: t('tools.task:statuses:text:open'), color: t('tools.task:statuses:colors:open') },
        in_progress: { label: t('tools.task:statuses:text:in_progress'), color: t('tools.task:statuses:colors:in_progress') },
        awaiting_feedback: { label: t('tools.task:statuses:text:awaiting_feedback'), color: t('tools.task:statuses:colors:awaiting_feedback') },
        closed: { label: t('tools.task:statuses:text:closed'), color: t('tools.task:statuses:colors:closed') }
    })

    initPiorities = () => ({
        undetermined: { label: t('tools.task:priorities:text:undetermined'), color: t('tools.task:priorities:colors:undetermined') },
        followup: { label: t('tools.task:priorities:text:followup'), color: t('tools.task:priorities:colors:followup') },
        normal: { label: t('tools.task:priorities:text:normal'), color: t('tools.task:priorities:colors:normal') },
        important: { label: t('tools.task:priorities:text:important'), color: t('tools.task:priorities:colors:important') },
        extremely_important: { label: t('tools.task:priorities:text:extremely_important'), color: t('tools.task:priorities:colors:extremely_important') }
    });

    handleResize = () => {
        let sortingEls = document.querySelectorAll('td[tabindex="0"]');
        if (sortingEls.length <= 0) return;

        let me = this;

        [].forEach.call(sortingEls, (el) => {
            el.addEventListener('click', (e) => {
                let parentEl = e.target.parentElement;
                if (!parentEl) return;

                setTimeout(() => {
                    let { itemId, patientId, watching } = parentEl.dataset;

                    let actionsEl = document.querySelector('.dtr-data #task-actions-' + itemId);
                    if (!actionsEl) return;

                    ReactDOM.render(<TaskActions id={itemId} data={{ patientId: patientId, watching: watching }} onLogClick={id => this.onStatusLogClick(id)} onDetailClick={id => me.renderDetailModal(id)} toggleWatcher={itemId => me.onWatcherToggle(itemId)} remove={itemId => me.modalRemove.fire({ id: itemId })} {...me.props} />, actionsEl);
                }, 100);
            });
        });
    }

    renderUnassignedBadge = (data) => {
        let existingBadge = document.getElementById('unassigned-count-badge');
        if (existingBadge) {
            existingBadge.parentNode.removeChild(existingBadge);
        }

        let unassignedCount = data.json && data.json.unassignedCount ? parseInt(data.json.unassignedCount) : 0;
        if (!unassignedCount) return;

        let parentEl = document.querySelector('.heading-subtitle');
        if (!parentEl) return;

        let badgeEl = document.createElement('span');
        badgeEl.id = 'unassigned-count-badge';
        badgeEl.classList.add('ml-3', 'badge', 'badge-lg', 'badge-warning');
        badgeEl.textContent = t('tools.task:unassignedCount', { count: unassignedCount });

        parentEl.innerHTML += badgeEl.outerHTML;
    }

    renderDetailModal = (id) => ReactDOM.render(<ModalTask {...this.props} id={id} removeModal={this.modalRemove} onRef={ref => this._modalDetail = ref} />, document.getElementById('modal-section'));

    loadGroups = () => ApiTaskService.getGroupsByGroup(this.props.group.id).then(results => this.setState({ groups: results }, () => this.renderAssignedFilter()));

    // Payment modal
    onPaymentModalClick = (id, payment) => this.setState({ nestedPaymentModal: true }, () => ReactDOM.render(<ModalPayment type="task" id={id} payment={payment} onToggle={this.toggleNestedPayment} parent={this} />, document.getElementById('modal-section')));
    toggleNestedPayment = (reload) => reload ? this.reload() : null;
    reload = () => this.setState({ loading: true }, () => this.filter());
}

const mapStateToProps = state => ({ group: state.group, stateSettings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DataTableTask);